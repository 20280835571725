<template>
    <div v-if="guide.data" class="guide-card">
        <div class="thumbnail-row">
            <NuxtLink :to="linkResolver(guide)" class="thumbnail-container">
                <PrismicImage :field="guide.data.thumbnail" :imgix-params="{w: 700}" />
            </NuxtLink>
        </div>

        <div class="top-info">
            <CategoryPill :category="guide.data.category" />
        </div>
        <NuxtLink :to="linkResolver(guide)" class="article-text">
            <h3 class="title">{{ guide.data.title }}</h3>
        </NuxtLink>
        <div class="tags">
            <TagPill v-for="tag in guide.tags" :key="tag" :tag="tag" />
        </div>
        <NuxtLink class="link" :to="linkResolver(guide)">{{ t('resources.guides.readLink') }}</NuxtLink>
    </div>
</template>

<script lang="ts" setup>
    import type {GuideDocument, GuideDocumentData} from '@prismicio-types';
    import {PrismicImage} from '@prismicio/vue';
    import {useTranslation} from '#imports';
    import TagPill from '~/components/TagPill.vue';
    import CategoryPill from '~/components/CategoryPill.vue';
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import linkResolver from '~/app/prismic/link-resolver';

    defineProps<{guide: GuideDocument | FilledContentRelationshipField<'guide', string, GuideDocumentData>}>();
    const {t} = useTranslation();
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .guide-card {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        .thumbnail-row {
            width: 100%;
            height: 335px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: $mobile-breakpoint) {
                height: 280px;
            }
            .thumbnail-container {
                position: relative;
                display: block;
                img {
                    max-height: 335px;
                    @media (max-width: $mobile-breakpoint) {
                        max-height: 280px;
                    }
                    max-width: 100%;
                    object-fit: contain;
                    border-radius: var(--joy-core-radius-3);
                    box-shadow: var(--joy-core-elevation-4);
                    position: relative;
                    transform: rotateZ(0deg);
                    transition: all var(--joy-transition-duration-long) var(--joy-transition-timing-function);

                    z-index: 3;
                }
                &::before {
                    content: '';
                    position: absolute;
                    background-color: white;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    border-radius: var(--joy-core-radius-3);
                    box-shadow: var(--joy-core-elevation-3);
                    z-index: 2;
                    transition: all var(--joy-transition-duration-long) var(--joy-transition-timing-function);
                    opacity: 0;
                }
                &::after {
                    content: '';
                    position: absolute;
                    background-color: white;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    border-radius: var(--joy-core-radius-3);
                    box-shadow: var(--joy-core-elevation-3);
                    z-index: 1;
                    transition: all var(--joy-transition-duration-long) var(--joy-transition-timing-function);
                    opacity: 0;
                }
            }
        }
        &:hover {
            .thumbnail-container {
                img {
                    transform: rotateZ(-4deg);
                }
                &::before {
                    opacity: 1;
                    transform: rotateZ(-2deg);
                }
                &::after {
                    opacity: 1;
                }
            }
        }

        .top-info {
            margin-top: var(--joy-core-spacing-10);
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .article-text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .title {
            margin-top: var(--joy-core-spacing-5);
        }

        .tags {
            margin-top: var(--joy-core-spacing-4);
            display: flex;
            gap: var(--joy-core-spacing-1);
            flex-wrap: wrap;
        }
        .link {
            @include link;
            margin-top: var(--joy-core-spacing-7);
        }
    }
</style>
